import React from 'react';
import './style.css';
import Cal from '../../components/buttons/cal';
import {Accordion, AccordionDetails, AccordionSummary, Typography,} from '@mui/material';
import {ExpandMore, SensorOccupied} from '@mui/icons-material';
import ilog from '../../images/cloud.jpg';
import ilog1 from '../../images/data.jpg';
import Connect from '../../components/connect/connect';


const Consulting = () => {

    return(
        <>
            <div className="soft-main">
                <div className="softerr">
                    <h2 className="softhero-head">Business & Enterprise IT Support Services</h2>
                    <p className="softhero-graph">
                    Partner with DOHTECH SOLUTIONS to optimize your enterprise IT, enhance efficiency, and focus on what you do best – driving your business forward. Let's make it happen! 
                    We provide  continuous monitoring and proactive maintenance to detect and resolve issues before they impact your operations, minimizing downtime and enhancing productivity.
                    </p>
                    <Cal text="Book Free Appointment" name="joinerbtn" />
                </div>
                <div className="dev-services">
                        <h1 className="dev-heading"> <span className="headline">Our</span> IT Consulting/Support Services</h1>
                        <div className="devspace">
                            <div className="dev">
                               <Accordion className="midn">
                                    <AccordionSummary
                                        expandIcon={<ExpandMore/>}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        className="mspkt"
                                    >
                                        Infrastructure Management
                                    </AccordionSummary>
                                    <AccordionDetails className="mspkti">
                                        Let us manage every aspect of your IT environment so you concentrate on running your business  while our team manages your IT infrastructure, ensuring it is reliable, secure, and optimized for performance. 
                                        <ul className="mspkti-ul">
                                            <li>Server Deployments</li>
                                            <li>Regular Server Patching and Upgrades</li>
                                            <li>Network Adminstration</li>
                                            <li>Full Server Management</li>
                                            <li>Cloud management</li>
                                            <li>Dependable 24/7 support</li>
                                        </ul>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion className="midn">
                                    <AccordionSummary
                                        expandIcon={<ExpandMore/>}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        className="mspkt"
                                    >
                                        Cloud Services and Migration
                                    </AccordionSummary>
                                    <AccordionDetails className="mspkti">
                                        We assist with cloud adoption, migration, and management, enabling you to leverage the benefits of cloud computing, such as scalability, flexibility, and cost-efficiency.
                                        <ul className="mspkti-ul">
                                            <li>AWS Cloud Support and Management</li>
                                            <li>Azure Cloud Support and Management</li>
                                            <li>Private VMWare Cloud support and Management</li>
                                            <li>Cloud Application Support</li>
                                            <li>Dependable 24/7 support</li>
                                        </ul>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion className="midn">
                                    <AccordionSummary
                                        expandIcon={<ExpandMore/>}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        className="mspkt"
                                    >
                                       Business Continuity and Disaster Recovery
                                    </AccordionSummary>
                                    <AccordionDetails className="mspkti">
                                        We develop and implement comprehensive disaster recovery plans to ensure your business can quickly recover from any unexpected events and continue operations with minimal disruption.
                                        <ul className="mspkti-ul">
                                            <li>Risk Assessment and Business Impact Analysis</li>
                                            <li>Business Continuity and Disaster Recovery Planning</li>
                                            <li>Data Backup Implementation</li>
                                            <li>High Availability Implementation</li>
                                            <li>Failover and Redundancy Solutions</li>
                                            <li> Continuous Monitoring and Alerts</li>
                                            <li>Disaster Recovery Testing and Drills</li>
                                            <li>Regulatory Compliance</li>
                                        </ul>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion className="midn">
                                    <AccordionSummary
                                        expandIcon={<ExpandMore/>}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        className="mspkt"
                                    >
                                         Software and Hardware Procurement
                                    </AccordionSummary>
                                    <AccordionDetails className="mspkti">
                                        We assist with the selection and procurement of the right software and hardware solutions, ensuring they meet your business needs and are integrated seamlessly into your environment.
                                        <ul className="mspkti-ul">
                                            <li>Needs Assessment and Requirements Gathering</li>
                                            <li>Vendor Evaluation and Selection</li>
                                            <li>Product Sourcing  </li>
                                            <li>Licensing and Compliance Management</li>
                                            <li>Installation and Configuration </li>
                                            <li>Ongoing Support and Maintenance</li>
                                            <li>IT Equipment Disposal and Recycling</li>
                                        </ul>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion className="midn">
                                    <AccordionSummary
                                        expandIcon={<ExpandMore/>}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        className="mspkt"
                                    >
                                        Datacenter Support and Management
                                    </AccordionSummary>
                                    <AccordionDetails className="mspkti">
                                        DOHTECH SOLUTIONS offers a comprehensive range of datacenter support and management services designed to ensure your datacenter operates efficiently, securely, and reliably.
                                        <ul className="mspkti-ul">
                                            <li>Datacenter Design and Implementation</li>
                                            <li>Infrastructure Management</li>
                                            <li>Monitoring and Alerting</li>
                                            <li>Backup and Recovery Solutions</li>
                                            <li>Security Management</li>
                                            <li>Virtualization and Cloud Integration</li>
                                            <li>Patch Management and Updates</li>
                                        </ul>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion className="midn">
                                    <AccordionSummary
                                        expandIcon={<ExpandMore/>}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        className="mspkt"
                                    >
                                        Cybersecurity Solutions
                                    </AccordionSummary>
                                    <AccordionDetails className="mspkti">
                                        We implement advanced security measures to protect your business from cyber threats, including firewalls, intrusion detection systems, and regular security audits.
                                        <ul className="mspkti-ul">
                                            <li>Security Audits and Compliance</li>
                                            <li>Network Security</li>
                                            <li>Penetration Testing</li>
                                            <li>Security Awareness Training</li>
                                            <li>Threat Intelligence and Monitoring</li>
                                            <li>Cloud Security</li>
                                            <li>Security Information and Event Management(SIEM)</li>
                                            <li>Identity and Access Management</li>
                                            <li>Data Encryption and Endpoint Protection</li>
                                        </ul>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion className="midn">
                                    <AccordionSummary
                                        expandIcon={<ExpandMore/>}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        className="mspkt"
                                    >
                                        Help Desk and Technical Support
                                    </AccordionSummary>
                                    <AccordionDetails className="mspkti">
                                        Our dedicated help desk provides round-the-clock support to resolve technical issues, answer queries, and assist with IT-related challenges, ensuring smooth operations.
                                        <ul className="mspkti-ul">
                                            <li>Service Desk Software Solutions</li>
                                            <li>Knowledge Base Management</li>
                                            <li>User Account Management</li>
                                            <li>User Training and Support</li>
                                            <li>Software and Hardware Support</li>
                                            <li>On-Site and Remote Technical Support</li>
                                            <li>Incident Management</li>
                                        </ul>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion className="midn">
                                    <AccordionSummary
                                        expandIcon={<ExpandMore/>}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        className="mspkt"
                                    >
                                        System Integration
                                    </AccordionSummary>
                                    <AccordionDetails className="mspkti">
                                    We provide seamless integration of various systems and applications, ensuring smooth data flow and interoperability across your IT ecosystem.
                                        <ul className="mspkti-ul">
                                            <li>System Architecture Design</li>
                                            <li>Application Integration</li>
                                            <li>Data Integration</li>
                                            <li>API Integration</li>
                                            <li>Legacy System Integration</li>
                                            <li>Custom Integration Solutions</li>
                                            <li>Continuous Monitoring and Support</li>
                                            <li>Testing and Quality Assurance</li>
                                            <li>Security Integration</li>
                                            <li>Cloud Integration</li>
                                        </ul>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion className="midn">
                                    <AccordionSummary
                                        expandIcon={<ExpandMore/>}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        className="mspkt"
                                    >
                                        IT Training and Development
                                    </AccordionSummary>
                                    <AccordionDetails className="mspkti">
                                        Our training programs help your team stay up-to-date with the latest technologies and best practices, ensuring they can effectively utilize your IT resources.
                                        <ul className="mspkti-ul">
                                            <li>Project-Based Training</li>
                                            <li>Change Management Training</li>
                                            <li>Vendor-Specific Training</li>
                                            <li>Security Awareness Training</li>
                                            <li>On-site and Online IT Training</li>
                                            <li>Custom Training Solutions</li>
                                        </ul>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="dev">
                                <img src={ilog} alt="software dev" className="dev-img"/>
                            </div>
                        </div>
                    </div>
                    <div className="chooseDiv">
                        <h1 className="choose-head"><span className="headline">Why</span> Work With US</h1>
                        <p className="choose-graph">
                        Transform your business operations, enhance performance, and boost team productivity with DohTech Solutions' bespoke IT support services. Our solutions are crafted to fit any use case, budget, and complexity, 
                        addressing both your current and future business needs.
                        </p>
                        <div className="chooser">
                            <div className="choose">
                                <img src={ilog1} alt="software dev" className="dev-imgd l"/>
                            </div>
                            <div className="choose">
                                <div className="choosen">
                                    <h3 className="cho-head">Comprehensive IT Expertise</h3>
                                    <p className="cho-graph">
                                    DOHTECH SOLUTIONS brings a wealth of knowledge and experience across various IT domains, from cybersecurity and system integration to help desk support and IT training. 
                                    Our team of experts is equipped to handle all your IT challenges efficiently and effectively.
                                    </p>
                                </div>
                                <div className="choosen">
                                    <h3 className="cho-head">Tailored Solutions</h3>
                                    <p className="cho-graph">
                                    We understand that every business is unique. Our customized IT solutions are designed to meet your specific requirements, ensuring that our services
                                     align perfectly with your business objectives and operational needs.
                                    </p>
                                </div>
                                <div className="choosen">
                                    <h3 className="cho-head"> Enhanced Productivity</h3>
                                    <p className="cho-graph">
                                    By leveraging our proactive IT services, your organization can improve its productivity and efficiency. We focus on optimizing your IT environment, reducing downtime, 
                                    and ensuring that your systems are always running at peak performance.
                                    </p>
                                </div>
                                <div className="choosen">
                                    <h3 className="cho-head">Scalability and Flexibility</h3>
                                    <p className="cho-graph">
                                    Our IT solutions are scalable, allowing you to easily adapt to changes in your business size and requirements. Whether you’re a growing startup or an established 
                                    enterprise, our services can scale to meet your needs.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="connectSect">
                        <Connect />
                    </div>
            </div>
        </>
    )
}

export default Consulting;