import React from 'react';
import './style.css';
import mainlogo from '../../images/dohtech2.png';
import {LinkedIn, X, YouTube} from '@mui/icons-material';
import {Link} from 'react-router-dom';

const Footer = () => {

    return(
        <>
            <footer className="foot-main">
                <div className="foot-div">
                    <div className="foot">
                        <img src={mainlogo} className="footer-logo" alt="footer dohtech logo" /> 
                        <p className="footer-desc">
                            We help small businesses build and deploy innovative technologies to improve  business operations and scale growth. Let us bring your vision to life.
                        </p>
                        <div className="foot-social">
                            <div className="social">
                                <Link to="https://www.linkedin.com/company/dohtech-solutions/" className="foot-links"><LinkedIn className="foot-ico" /></Link>
                            </div>
                            <div className="social">
                                <Link to="https://twitter.com/dohtech237" className="foot-links"><X className="foot-ico" /></Link>
                            </div>
                            <div className="social">
                                <Link to="https://www.youtube.com/channel/UCJBQwXLI3ZzrAQudFCbO7og" className="foot-links"><YouTube className="foot-ico" /></Link>
                            </div>
                        </div>
                    </div>
                    <div className="foot">
                        <h3 className="comp-head">Company</h3>
                        <div className="company">
                            <ul className="links">
                                <li><Link to="/dohtech-msp" className="foo-li">MSP</Link></li>
                                <li><Link to="/dohtech-hosting" className="foo-li">Hosting</Link></li>
                                <li><Link to="/products" className="foo-li">Products</Link></li>
                                <li><Link to="/services" className="foo-li">Services</Link></li>
                                <li><Link to="/about" className="foo-li">About</Link></li>
                                <li><Link to="/contact" className="foo-li">Contact</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="foot">
                        <h3 className="comp-head">Products</h3>
                        <div className="company">
                            <ul className="links">
                                <li><Link target="_blank" to="https://buzmeapp.com" className="foo-li">Buzme Marketplace</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="foot">
                        <h3 className="comp-head">Join US</h3>
                        <div className="company">
                            <ul className="links">
                                <li><Link to="/#" className="foo-li">Hire US</Link></li>
                                <li><Link to="/#" className="foo-li">Become a partner</Link></li>
                                <li><Link to="/#" className="foo-li">Careers</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="copywrite">
                    <p className="copy">All rights reserved 2024 &copy; DOHTECH SOLUTIONS </p>
                </div>
            </footer>
        </>
    )
}

export default Footer;