import React from 'react';
import './style.css';
import {Link} from 'react-router-dom';
import Cal from '../../components/buttons/cal';
import Connect from '../../components/connect/connect';


const Services = () => {

    return(
        <>
           <div className="main-service">
               <section className="service-hero">
                   <div className="service">
                        <h3 className="service-head">Boost business performance with Tailored Innovative Solutions</h3>
                        <p className="service-desc">
                            Unlock the full potential of your business with DOHTECH SOLUTIONS, where we specialize in providing 
                            customized and innovative tech solutions tailored to meet your unique challenges and drive success.
                        </p>
                        <Cal text="Free Appointment" name="joinerbtn" />
                   </div>
               </section>
               <section className="server-types">
                    <h3 className="types-head"> We Offer Purpose Built Solutions <br/> For <span className="tip">Your</span> Business</h3>
                    <div className="types-main">
                        <div className="typer">
                            <img src="https://res.cloudinary.com/dohtech/image/upload/v1707623277/dohtech/weber_houmdk.png" alt="web development service" className="type-img" />
                            <h3 className="typer-hd">Custom Web Development</h3>
                            <p className="typer-desc">
                                Get a custom web based application or website built to solve any business problem, automate manual business 
                                operations or bring your vision to life. We build, deploy and manage your application so you can concentrate on running your business efficiently.
                            </p>
                        </div>
                        <div className="typer">
                            <img src="https://res.cloudinary.com/dohtech/image/upload/v1707625253/dohtech/app_qilsva.png" alt="web development service" className="type-img" />
                            <h3 className="typer-hd">Mobile App Development</h3>
                            <p className="typer-desc">
                                Do you have an innovative idea and need help building it? or does your business need a mobile app?  We develop and maintain OS specific and cross platform mobile applications for Android and IOS devices.
                                We also offer consulting services to help you identify all what your app needs.
                            </p>
                        </div>
                        <div className="typer">
                            <img src="https://res.cloudinary.com/dohtech/image/upload/v1707625478/dohtech/entapp_wmusx7.png" alt="web development service" className="type-img" />
                            <h3 className="typer-hd">Enterprise Applications</h3>
                            <p className="typer-desc">
                                Enterprises require unique applications to sustain the large number of processes that maintain their business operations. 
                                Let us increase your enterprise efficiency and develop the solution to that bottleneck problem or manual process with automation.
                            </p>
                        </div>
                        <div className="typer">
                            <img src="https://res.cloudinary.com/dohtech/image/upload/v1707615172/dohtech/servered_wq2ylg.png" alt="web development service" className="type-img" />
                            <h3 className="typer-hd">IT Consulting & Cloud Support</h3>
                            <p className="typer-desc">
                                Managing IT environments is complex and can become very difficult, resource and time consuming especially in organizations with small teams or few resources 
                                to get many engineers. Let us maintain your environment ensuring 24/7 support for all your systems and applications.
                            </p>
                        </div>
                        <div className="typer">
                            <img src="https://res.cloudinary.com/dohtech/image/upload/v1709975794/dohtech/Support-amico_ry1dru.png" alt="web development service" className="type-img" />
                            <h3 className="typer-hd">Support & Maintenance</h3>
                            <p className="typer-desc">
                                Get peace of mind with our 24/7 support and routine maintenance based on the needs of your application or IT environment. 
                                Our support services are designed to address issues as quick as possible before they affect your end users.
                            </p>
                        </div>
                        <div className="typer">
                            <img src="https://res.cloudinary.com/dohtech/image/upload/v1707545544/dohtech/softdev_hvz0hi.png" alt="web development service" className="type-img" />
                            <h3 className="typer-hd">Application Upgrades & Redesign</h3>
                            <p className="typer-desc">
                                Custom applications require constant updating to stay up to date with industry trends and standards. We can help upgrade your existing application including 
                                redesign and adding new functionalities or integrations to other internet resources.
                            </p>
                        </div>
                    </div>
                    <Connect />
               </section>
           </div>
        </>
    )
}

export default Services;