import React from 'react';
import './style.css';
import Cal from '../../components/buttons/cal';
import {Accordion, AccordionDetails, AccordionSummary, Typography,} from '@mui/material';
import {ExpandMore, SensorOccupied} from '@mui/icons-material';
import ilog from '../../images/training.jpg';
import ilog1 from '../../images/trainingclass.jpg';
import Connect from '../../components/connect/connect';



const Training = () => {

    return(
        <>
            <div className="soft-main">
                    <div className="softerr">
                        <h2 className="softhero-head">Enterprise Software & IT  Skills Training</h2>
                        <p className="softhero-graph">
                          Master high in demand IT skills through our comprehensive training programs to turn any novice into an industry expert. Whether you're looking to start 
                          a new career in IT or enhance your existing skills, DohTech Solutions' Enterprise Software & IT Skills Training is your pathway to success. We are dedicated to empowering 
                          individuals with the knowledge and skills required to thrive in the competitive IT landscape.
                        </p>
                        <Cal text="Book Free Appointment" name="joinerbtn" />
                    </div>
                    <div className="dev-services">
                        <h1 className="dev-heading"> <span className="headline">Our</span> Expert Training Programs</h1>
                        <div className="devspace">
                            <div className="dev">
                               <Accordion className="midn">
                                    <AccordionSummary
                                        expandIcon={<ExpandMore/>}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        className="mspkt"
                                    >
                                        Full Stack Software Development
                                    </AccordionSummary>
                                    <AccordionDetails className="mspkti">
                                        Become a seasoned software developer with dynamic skills to handle both backend and frontend development and jumpstart your career as a Full stack developer.
                                        <ul className="mspkti-ul">
                                            <li>Front-End Development</li>
                                            <li>Backend Development</li>
                                            <li>Full Stack Development</li>
                                            <li>Mobile Application Development</li>
                                            <li>Cross Platform Application Development</li>
                                            <li>Direct Mentorship from DOHTECH Expert Engineers</li>
                                        </ul>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion className="midn">
                                    <AccordionSummary
                                        expandIcon={<ExpandMore/>}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        className="mspkt"
                                    >
                                       IT System Engineering
                                    </AccordionSummary>
                                    <AccordionDetails className="mspkti">
                                        Become an IT professional through our IT system Engineering  program designed to help you build mastry around key high indemand IT technologies to launch your IT career.
                                        <ul className="mspkti-ul">
                                            <li>Linux System Administration</li>
                                            <li>Windows System Administration</li>
                                            <li>Cloud System Engineering</li>
                                        </ul>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion className="midn">
                                    <AccordionSummary
                                        expandIcon={<ExpandMore/>}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        className="mspkt"
                                    >
                                        Database Administration
                                    </AccordionSummary>
                                    <AccordionDetails className="mspkti">
                                        Master the Ins and Outs of Database Administration from seasoned experts. This program is designed to make masters out of novices with focus on industry leading databases with heavy practicals to ensure Mastery.
                                        <ul className="mspkti-ul">
                                            <li>SQL Database Administration</li>
                                            <li>NOSQL Database Adminstrations</li>
                                            <li>Cloud Database Admininstration</li>
                                        </ul>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion className="midn">
                                    <AccordionSummary
                                        expandIcon={<ExpandMore/>}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        className="mspkt"
                                    >
                                        Devops Engineering
                                    </AccordionSummary>
                                    <AccordionDetails className="mspkti">
                                        We believe in learning by doing. Our training program includes practical, hands-on experience with real-world projects and scenarios. 
                                        This approach ensures you can apply theoretical concepts to practical situations, making you job-ready from day one.
                                        <ul className="mspkti-ul">
                                            <li>Continuous Integration/Continuous Deployment (CI/CD)</li>
                                            <li>Containerization and Orchestration</li>
                                            <li>Infrastructure as Code (IaC)</li>
                                            <li>Monitoring and Logging</li>
                                            <li>Cloud Computing (AWS/GCP/AZURE) </li>
                                        </ul>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion className="midn">
                                    <AccordionSummary
                                        expandIcon={<ExpandMore/>}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        className="mspkt"
                                    >
                                        Public / Private Cloud Architech Engineering
                                    </AccordionSummary>
                                    <AccordionDetails className="mspkti">
                                    Our training program covers a wide range of cloud platforms and tools, providing you with a comprehensive skill set that is highly 
                                    valued in the IT industry. This versatility enables you to tackle various challenges and contribute effectively to your organization's success.
                                        <ul className="mspkti-ul">
                                            <li>AWS </li>
                                            <li>Microsoft Azure</li>
                                            <li>GCP</li>
                                            <li>VMWare Vsphere</li>
                                            <li>Oracle Cloud</li>
                                        </ul>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion className="midn">
                                    <AccordionSummary
                                        expandIcon={<ExpandMore/>}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        className="mspkt"
                                    >
                                        IT Infrastructure Engineering
                                    </AccordionSummary>
                                    <AccordionDetails className="mspkti">
                                        Infrastructure engineers serve as a bridge to different IT responsibilities being able to handle from network to server management and automation services. 
                                        Learn how to manage different aspects of an IT infrastructure which equips you with highly demanded skills to fit into any organization.
                                        <ul className="mspkti-ul">
                                            <li>Network Administration</li>
                                            <li>Server Administration</li>
                                            <li>Firewall Administration</li>
                                            <li>IT Automation with configuration managers</li>
                                            <li>Linux advanced scripting  on bash and python</li>
                                            <li>Containerization (Docker & Kubernetes)</li>
                                        </ul>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion className="midn">
                                    <AccordionSummary
                                        expandIcon={<ExpandMore/>}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        className="mspkt"
                                    >
                                        AWS Cloud Certification Preparation
                                    </AccordionSummary>
                                    <AccordionDetails className="mspkti">
                                        AWS certified cloud engineers are the most sort after in the IT industry around the world. Learn from certified practicing AWS certified engineers to learn the ins and outs of the certification exam and stand confident to pass with flying colors in exam day.
                                        <ul className="mspkti-ul">
                                            <li>AWS Certified Solutions Architect Training</li>
                                            <li>AWS Certified Solutions Professional Training</li>
                                            <li>AWS Certified Devops Engineer Training</li>
                                        </ul>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion className="midn">
                                    <AccordionSummary
                                        expandIcon={<ExpandMore/>}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        className="mspkt"
                                    >
                                        Advanced IT Infrastructure Automation
                                    </AccordionSummary>
                                    <AccordionDetails className="mspkti">
                                        IT Infrastructure Automation enables engineers to perform repetitive highly technical tasks consistently and efficiently everytime while saving time, preventing human errors and improving work performance.
                                        Learn how to automate different infrastructure tasks using configuration managers like Ansible and Puppet through practical examples from Our highly experienced engineers.
                                        <ul className="mspkti-ul">
                                            <li>Ansible Automation</li>
                                            <li>Puppet Automation</li>
                                            <li>Advanced python and bash scripting</li>
                                        </ul>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion className="midn">
                                    <AccordionSummary
                                        expandIcon={<ExpandMore/>}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        className="mspkt"
                                    >
                                       Network Administration
                                    </AccordionSummary>
                                    <AccordionDetails className="mspkti">
                                        Become a highly skilled Network Engineer through our highly practical training program designed to take you from a novice to a pro in no time. 
                                        Learn from our experienced engineers including direct access to a full production environment with real Network devices such as firewalls, switches etc.
                                        <ul className="mspkti-ul">
                                            <li>Datacenter Admininstration</li>
                                            <li>Switch Adminstration</li>
                                            <li>Firewall Adminstration</li>
                                            <li>Network architechture and Connectivity Design</li>
                                            <li>Network Security Management</li>
                                            <li>Network Protocols Mastry</li>
                                        </ul>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="dev">
                                <img src={ilog} alt="software dev" className="dev-img"/>
                            </div>
                        </div>
                    </div>
                    <div className="chooseDiv">
                        <h1 className="choose-head"><span className="headline">Why Learn</span>  At DOHTECH IT SOLUTIONS ACADEMY </h1>
                        <p className="choose-graph">
                            We offer both online and on-site training options to suit your schedule and learning preferences. Our online courses include live webinars, recorded sessions, and interactive modules, providing flexibility and convenience. 
                            On-site training sessions are available for those who prefer a more traditional learning environment
                        </p>
                        <div className="chooser">
                            <div className="choose">
                                <img src={ilog1} alt="software dev" className="dev-imd"/>
                            </div>
                            <div className="choose">
                                <div className="choosen">
                                    <h3 className="cho-head">Hands-On Learning Experience</h3>
                                    <p className="cho-graph">
                                    We believe in learning by doing. Our training includes practical, hands-on experience with real-world projects and scenarios. This approach ensures that you can apply theoretical concepts to practical situations, making you job-ready from day one.
                                    </p>
                                </div>
                                <div className="choosen">
                                    <h3 className="cho-head">Industry-Recognized Certifications</h3>
                                    <p className="cho-graph">
                                    Our courses prepare you for industry-recognized certifications that validate your skills and enhance your employability. Certifications from CompTIA, Cisco, Microsoft, VMware, and other 
                                    leading providers are integral parts of our training programs, giving you a competitive edge in the job market.
                                    </p>
                                </div>
                                <div className="choosen">
                                    <h3 className="cho-head">Expert Instructors</h3>
                                    <p className="cho-graph">
                                    Our instructors are seasoned IT professionals with extensive industry experience. They bring real-world insights and practical knowledge to the classroom, helping you understand complex infrastructure concepts and stay updated with the latest industry trends.
                                    </p>
                                </div>
                                <div className="choosen">
                                    <h3 className="cho-head">Continuous Support</h3>
                                    <p className="cho-graph">
                                    Our commitment to your success doesn’t end with the completion of the course. We provide continuous support through mentorship, additional resources, and access to a community of IT professionals. 
                                    This ongoing support helps you stay updated with the latest developments in the field and continue your professional growth.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="connectSect">
                        <Connect />
                    </div>
            </div>
        
        </>
    )
}

export default Training;