import React from 'react';
import './style.css';
import {Assistant} from '@mui/icons-material';
import Cal from '../../components/buttons/cal';
import Connect from '../../components/connect/connect';
import { Link } from 'react-router-dom';
import mager1 from '../../images/missions.jpg';
import mager2 from '../../images/vision.jpg';



const About = () => {

    return(
        <>
           <div className="about-main">
                <section className="about-hero">
                    <div className="abt-texter">
                        <h1 className="about-head">Creating Smart Solutions to Complex business problems </h1>
                        <p className="about-txt">
                            We assist businesses to streamline their operations, automate processes, and unlock new opportunities for growth through
                            tailored software solutions and expert consulting dedicated to supporting your business every step of the way. 
                        </p>
                        <Cal text="Free Appointment" name="joinerbtn"/>
                    </div>
                </section>
                <section className="whyus-main">
                    <h1 className="whyus-head">Why Work With US</h1>
                    <div className="whyus-div">
                        <div className="whyus">
                            <Assistant  className="whyus-ico"/>
                            <h3 className="whyus-hd">Expertise</h3>
                            <p className="whyus-txt">
                                We have a team of passionate engineers with experience solving complex business problems including building and managing complex tech stacks.
                            </p>
                        </div>
                        <div className="whyus">
                            <Assistant  className="whyus-ico"/>
                            <h3 className="whyus-hd">Tailored Solutions</h3>
                            <p className="whyus-txt">
                                We customize solutions for each unique problem, ensuring they fully resolve the issue and are future-proof for easy upgrades.
                            </p>
                        </div>
                        <div className="whyus">
                            <Assistant  className="whyus-ico"/>
                            <h3 className="whyus-hd">Client-Centric Approach</h3>
                            <p className="whyus-txt">
                                We prioritize you, our client, by crafting tailored solutions that exceed expectations with  a personalized service and an innovative solutions designed specifically for you.
                            </p>
                        </div>
                        <div className="whyus">
                            <Assistant className="whyus-ico" />
                            <h3 className="whyus-hd">Continuous Innovation</h3>
                            <p className="whyus-txt">
                                We're committed to keeping you ahead with cutting-edge solutions that evolve alongside your needs. 
                            </p>
                        </div>
                    </div>
                    <div className="motive">
                        <div className="mot">
                            <img src={mager1} alt="about-imager" className="abouter" />
                            <div className="motiver">
                                <h3 className="mot-head">Our Mission</h3>
                                <p className="mot-txt">
                                    Empowering progress through innovative technology solutions, DOHTECH SOLUTIONS is dedicated to enhancing efficiency, fostering growth, and delivering exceptional value to our clients worldwide.
                                </p>
                            </div>
                        </div>
                        <div className="mot">
                            <img src={mager2} alt="about-imager" className="abouter l" />
                            <div className="motiver">
                                <h3 className="mot-head">Our Vision</h3>
                                <p className="mot-txt">
                                    Envisioning a world where technology transcends boundaries, DOHTECH SOLUTIONS aim to be at the forefront of innovation, inspiring positive change and driving global progress through cutting-edge technologies that redefine possibilities and enrich lives.
                                </p>
                            </div>
                        </div>
                    </div>
                </section> 
                <section className="tech-main">
                    <div className="main">
                        <h3 className="tech-heading">Dependable IT Infrastructure Management</h3>
                        <p className="tech-txt">
                            Get the peace of mind you need to focus on running your business while we handle every aspect of your IT with our 24/7 system monitoring ready to handle any emergency.
                            With us on your team, your business operations will run more efficiently with specialty technologies deployed to reduce manual tasks and enhance productivity.
                        </p>
                        <Link to="/it-cloud-consulting" className="conbtn" >IT Support Services</Link>
                    </div>
                    <div className="main">
                        <img src="https://res.cloudinary.com/dohtech/image/upload/v1707545544/dohtech/server_klhax0.png" alt="support icon" className="main-img" />
                    </div>
                    <Connect />
                </section>  
           </div>
        </>
    )
}

export default About; 