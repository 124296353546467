import React from 'react';
import './style.css';
import Cal from '../../components/buttons/cal';
import {Accordion, AccordionDetails, AccordionSummary, Typography,} from '@mui/material';
import { ExpandMore, Engineering, Savings, MonetizationOn, DonutLarge} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import {Link} from 'react-router-dom';
import Connect from '../../components/connect/connect';



const MSP = () => {

    return(
        <>
            <div className="msp-main">
                <section className="msp-hero">
                    <div className="msp">
                        <h1 className="msp-heading">Proactive IT Management with <br/> DOHTECH MSP Solutions</h1>
                        <p className="msp-txt">
                            Enhance your business with our proactive IT services designed to enhance your organization’s productivity and efficiency.
                            we specialize in offering tailored IT solutions to meet the daily requirements of businesses like yours. <br/><br/>
                            Let us simplify your IT environment, reduce IT expenditures, and enhance your business performance.
                        </p>
                        <Cal text="Book Free Appointment" name="joinerbtn" />
                    </div>
                </section>
                <section className="msp-kind">
                    <h3 className="mspk-head"><span className="yline">Our</span> Managed IT Services</h3>
                    <div className="mspk-div">
                        <div className="mspk">
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMore/>}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                className="mspkt"
                            >
                                Infrastructure Server Management
                            </AccordionSummary>
                            <AccordionDetails className="mspkti">
                                Experience comprehensive 24/7 server management across all your IT environments, coupled with in-depth monitoring and proactive resolution of service-impacting issues. Our dedicated team ensures the continuous availability and optimal performance of your servers, allowing you to focus on driving your business forward with confidence.  
                                <ul className="mspkti-ul">
                                    <li>New server deployments</li>
                                    <li>Monthly security patches</li>
                                    <li>Routine server maintenance</li>
                                    <li>Advanced infrastructure monitoring</li>
                                    <li>Server resource management</li>
                                    <li>Network management</li>
                                </ul>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel2-content"
                                id="panel2-header"
                                className="mspkt"
                            >
                                Cloud Support &  Management
                            </AccordionSummary>
                            <AccordionDetails className="mspkti">
                            We deliver end-to-end cloud support, guiding you through initial setup to ongoing management with a steadfast focus on security, compliance, and cost efficiency. Our expert team ensures your cloud resources not only operate seamlessly but also remain within your allocated
                            monthly expenditure budgets, providing you with peace of mind and financial stability as you navigate the digital realm.
                                <ul className="mspkti-ul">
                                    <li>Create and manage compute reosurces </li>
                                    <li>Manage access and firewall rules</li>
                                    <li>AWS resource management (EC2, EBS, S3 etc)</li>
                                    <li>Azure resource management</li>
                                    <li>Identity and access control management (IAM)</li>
                                    <li>Cloud billing</li>
                                    <li>Cloud Security</li>
                                </ul>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel2-content"
                                id="panel2-header"
                                className="mspkt"
                            >
                                Business Application Management
                            </AccordionSummary>
                            <AccordionDetails className="mspkti">
                                Mordern businesses rely on multiple software applications for efficient operations and managing them can be combasome or time consuming.
                                Let us do the heavy lifting for you and manage your saas.
                                <ul className="mspkti-ul">
                                    <li>New Saas application deployments</li>
                                    <li>Saas application management</li>
                                    <li>Microsoft 365 Administration</li>
                                    <li>Google Drive Adminstration</li>
                                    <li>Dropbox Adminstration</li>
                                </ul>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel2-content"
                                id="panel2-header"
                                className="mspkt"
                            >
                                Active Directory Management
                            </AccordionSummary>
                            <AccordionDetails className="mspkti">
                                Microsoft Active Directory (AD) is a key tool to most businesses and management can be time consuming for smaller companies or teams. Let us provide the ultimate management and AD maintenance for your business operations.
                                <ul className="mspkti-ul">
                                    <li>Standard user profile management</li>
                                    <li>Group policy configuration </li>
                                    <li>User account lifecycle management</li>
                                    <li>AD maintenance and security patching</li>
                                    <li>AD Domain management</li>
                                </ul>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel2-content"
                                id="panel2-header"
                                className="mspkt"
                            >
                                Remote Help-desk / On-site Support
                            </AccordionSummary>
                            <AccordionDetails className="mspkti">
                                Technology is the backbone of modern business operations, ensuring efficiency and productivity. However, occasional failures can disrupt operations and hinder usability. That's why having a team of experienced engineers is essential. 
                                Our dedicated experts are poised to swiftly troubleshoot and resolve any issues, ensuring minimal disruptions and maintaining uninterrupted functionality of your technology infrastructure.
                                <ul className="mspkti-ul">
                                    <li>Remote helpdesk troubleshooting</li>
                                    <li>IT asset tracking and management</li>
                                    <li>Computer and software training</li>
                        
                                </ul>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMore/>}
                                aria-controls="panel2-content"
                                id="panel2-header"
                                className="mspkt"
                            >
                                Business Cybersecurity & Monitoring
                            </AccordionSummary>
                            <AccordionDetails className="mspkti">
                                In today's digital age, safeguarding your business is non-negotiable. At DOHTECH Solutions, we specialize in providing robust cybersecurity solutions tailored to protect your business from cyber threats. 
                                <ul className="mspkti-ul">
                                    <li>Proactive threat detection & prevention</li>
                                    <li>Endpoint protection </li>
                                    <li>Email security</li>
                                    <li>VPN setup and management</li>
                                    <li>Multi-factor authentication </li>
                                    <li>Single Sign-On setup and management</li>
                                    <li>Security Awareness training</li>
                                    <li>24/7 Security monitoring and Alerting </li>
                                </ul>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMore/>}
                                aria-controls="panel2-content"
                                id="panel2-header"
                                className="mspkt"
                            >
                                Backups and Disaster Recovery
                            </AccordionSummary>
                            <AccordionDetails className="mspkti">
                                Ensuring the safety of your data is paramount for business continuity, particularly in the face of unforeseen disasters. At DOHTECH Solutions, we specialize in crafting comprehensive backup and disaster recovery strategies tailored 
                                to safeguard your critical data.
                                <ul className="mspkti-ul">
                                    <li>Onsite and Offsite data redundancy</li>
                                    <li>NAS Setup and management</li>
                                    <li>Regular backups and backup verification</li>
                                    <li>Extensive reports and monitoring</li>
                                    <li>Immediate backup troubleshooting</li>
                                    <li>Advanced security</li>
                                </ul>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMore/>}
                                aria-controls="panel2-content"
                                id="panel2-header"
                                className="mspkt"
                            >
                                Virtual CTO/CIO
                            </AccordionSummary>
                            <AccordionDetails className="mspkti">
                                Amidst the growth of your business, the demand for efficient technology escalates. Determining the ideal technologies for various use cases can pose challenges. However, with our Virtual CIO/CTO service, you can rest assured. Serving as your Virtual Chief Information/Technology Officer, we tailor technology solutions to match your company's needs and streamline operations, all while cutting costs.
                                <ul className="mspkti-ul">
                                    <li>Business challenges and goal analysis</li>
                                    <li>Technology analysis meetings</li>
                                    <li>Strategy meetings </li>
                                    <li>Regular reports</li>
                                    <li>technology implementation planning and execution</li>
                                </ul>
                            </AccordionDetails>
                        </Accordion>
                        </div>
                        <div className="mspk">
                            <img src="https://res.cloudinary.com/dohtech/image/upload/v1710013237/dohtech/msp_tcy1il.jpg" alt="msp services" className="mspk-img" />
                        </div>
                    </div>
                </section>
                <section className="startup">
                    <div className="startup-div">
                        <div className="start">
                        <h3 className="startup-head"><span className="yline">MSP</span> For Your Startup</h3>
                        <p className="startup-desc">
                            At DOHTECH Solutions, we understand the challenges startups face in today's competitive landscape. With our MSP service, you gain access to dedicated experts providing comprehensive IT support every step of the way.
                        </p>
                            <img src="https://res.cloudinary.com/dohtech/image/upload/v1711828572/dohtech/goal_vd5nfo.jpg" alt="startup image" className="startup-img" />
                        </div>
                        <div className="start">
                            <div className="status">
                                <img src="https://res.cloudinary.com/dohtech/image/upload/v1711829110/dohtech/pop1_axz3vs.png" alt="status image" className="status-img" />
                                <h3 className="status-head">Cost Effective Solutions</h3>
                                <p className="status-desc">
                                    We offer budget-friendly MSP packages for startups, allowing you to scale your IT services as you grow without overspending.
                                </p>
                            </div>
                            <div className="status">
                                <img src="https://res.cloudinary.com/dohtech/image/upload/v1711829111/dohtech/pop2_z4fcim.png" alt="status image" className="status-img" />
                                <h3 className="status-head">Expert Guidance and Support</h3>
                                <p className="status-desc">
                                    Our experienced IT team offers comprehensive support, from strategic planning to proactive monitoring and troubleshooting, ensuring you navigate IT management confidently.
                                </p>
                            </div>
                            <div className="status">
                                <img src="https://res.cloudinary.com/dohtech/image/upload/v1711829110/dohtech/pop3_okly26.png" alt="status image" className="status-img" />
                                <h3 className="status-head">Scalability and Flexibility</h3>
                                <p className="status-desc">
                                    Our MSP solutions scale with your startup, accommodating changing needs as you grow. Whether adding users, launching products, or entering new markets, we're here to support you at every stage.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="yus-main">
                    <h3 className="yus-head"><span className="yline">Why</span> Make US Your Technology Partner?</h3>
                    <div className="yus-div">
                        <div className="yus">
                            <Engineering className="yus-ico" />
                            <h3 className="yust e">Access To Experienced Engineers</h3>
                            <p className="yust-txt">
                            Hiring experienced engineers can be a significant expense, especially for smaller companies. However, with DOHTECH as your Managed Service Provider (MSP), you gain direct access to our team of seasoned engineers without 
                            the hefty price tag. We provide comprehensive support for all your technological needs, ensuring top-notch expertise without breaking the bank.
                            </p>
                        </div>
                        <div className="yus">
                            <Savings className="yus-ico" />
                            <h3 className="yust">IT Cost Reduction </h3>
                            <p className="yust-txt">
                                Optimize your IT environment and cut costs with our expert guidance on deploying the best technologies. We provide recommendations on utilizing both free and open-source alternatives, allowing your business to save money 
                                without compromising efficiency. Trust us to help you achieve cost savings while maintaining peak performance in your operations.
                            </p>
                        </div>
                        <div className="yus">
                            <MonetizationOn className="yus-ico" />
                            <h3 className="yust">Fixed Monthly Fee</h3>
                            <p className="yust-txt">
                                Experience peace of mind with our Fixed Monthly Fee model, designed to provide budget predictability and cost stability for your business. With this transparent pricing structure, you can confidently plan your IT expenditures without unexpected surprises. 
                                Our tailored solutions ensure you receive the support and services you need, without fluctuating costs.
                            </p>
                        </div>
                        <div className="yus">
                            <DonutLarge className="yus-ico" />
                            <h3 className="yust">Increased  Productivity & Efficiency</h3>
                            <p className="yust-txt">
                                We specialize in optimizing your IT infrastructure and workflows to streamline operations and empower your team to achieve more. By implementing cutting-edge technologies and best practices, we help maximize your workforce's potential 
                                while minimizing downtime and inefficiencies.
                            </p>
                        </div> 
                    </div>
                </section>
                <section className="reach">
                    <Connect />
                </section>
                
            </div>
        </>
    )
}

export default MSP;