import React from 'react';
import { getCalApi } from "@calcom/embed-react";
import { useEffect } from "react";
import './style.css';
  



const Cal = ({text, name}) => {

	useEffect(()=>{
	  (async function () {
		const cal = await getCalApi();
		cal("ui", {"styles":{"branding":{"brandColor":"#2A2642"}},"hideEventTypeDetails":false,"layout":"month_view"});
	  })();
	}, [])

	return (
        <>
            <button data-cal-namespace="" className={name}
	            data-cal-link="dohtech-solutions/30min"
	            data-cal-config='{"layout":"month_view"}'
	        >{text}</button>
        
        </>
    )
  };
  export default Cal;
  