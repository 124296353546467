import React from 'react';
import './style.css';
import '../msp/style.css';
import Cal from '../../components/buttons/cal';
import {Accordion, AccordionDetails, AccordionSummary, Typography,} from '@mui/material';
import {ExpandMore, } from '@mui/icons-material';
import {Link} from 'react-router-dom';
import Connect from '../../components/connect/connect';


const Hosting = () => {

    return(
        <>
            <div className="hosting-main">
                <section className="hosting-hero">
                    <h1 className="hosting-head">Simplified Application and Website Hosting Solutions</h1>
                    <p className="hosting-txt">
                        Secure top-tier hosting for your website, web app, or application backend, backed by our team of experienced engineers. 
                        Our hosting solutions offer the flexibility to host on your hardware or our secure systems, all at a fixed monthly rate, with comprehensive support from our team.
                    </p>
                    <Cal text="Free Appointment" name="joinerbtn" />
                </section>
                <section className="uget-main">
                    <h3 className="uget-head">Our Hosting Guarantees</h3>
                    <div className="numb-div">
                        <div className="numb">
                            <h4 className="numbt">99.9%</h4>
                            <p className="numbp">
                                Uptime and Service Availability
                            </p>
                        </div>
                        <div className="numb">
                            <h4 className="numbt">24/7</h4>
                            <p className="numbp">
                                Monitoring and customer Support
                            </p>
                        </div>
                        <div className="numb">
                            <h4 className="numbt">100%</h4>
                            <p className="numbp">
                                Incremental upgrades and integration
                            </p>
                        </div>
                        <div className="numb">
                            <h4 className="numbt">1Gbps</h4>
                            <p className="numbp">
                                Unmetered bandwidth
                            </p>
                        </div>
                    </div>
                </section>
                <section className="mid-div">
                    <div className="mid">
                        <img src="https://res.cloudinary.com/dohtech/image/upload/v1710468475/dohtech/hostn_z8wbr8.png" alt="hosting posture" className="mid-img" />
                    </div>
                    <div className="mid">
                        <h3 className="mid-head">Reliable Hosting and Support Combined</h3>
                        <Accordion className="midn">
                            <AccordionSummary
                                expandIcon={<ExpandMore/>}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                className="mspkt"
                            >
                                Managed WordPress Website Hosting
                            </AccordionSummary>
                            <AccordionDetails className="mspkti">
                                Get the best wordpress hosting for your wordpress website with up to 30GB of free storage space at no extra cost. Choose to manage it yourself or have our team of experienced engineers manage every aspect of your website needs.
                                <ul className="mspkti-ul">
                                    <li>Content management</li>
                                    <li>Monthly security updates</li>
                                    <li>Integration and plugin setup</li>
                                    <li>Custom plugins for your specific needs</li>
                                    <li>Premium or Custom made themes just for your business</li>
                                    <li>Dependable 24/7 support</li>
                                </ul>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className="midn">
                            <AccordionSummary
                                expandIcon={<ExpandMore/>}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                className="mspkt"
                            >
                               Backend Application Hosting and Support
                            </AccordionSummary>
                            <AccordionDetails className="mspkti">
                                Get full support for your application's backend ensuring high availability and data redundancy. As your users increase in size, more resources and support is needed to ensure
                                users can efficiently connect and enjoy your app. Let us provide the reliability your applications need to scale.
                                <ul className="mspkti-ul">
                                    <li>Application deployments</li>
                                    <li>Server resource management</li>
                                    <li>Server troubleshooting</li>
                                    <li>Log monitoring and observability setup</li>
                                    <li>Load balancing</li>
                                </ul>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className="midn">
                            <AccordionSummary
                                expandIcon={<ExpandMore/>}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                className="mspkt"
                            >
                                Dedicated Purpose Built VPS
                            </AccordionSummary>
                            <AccordionDetails className="mspkti">
                                Flexible VPS hosting platform to deploy your online projects. Economical and balanced between processor cores, memory, and storage
                                <ul className="mspkti-ul">
                                    <li>Performance optimized vps</li>
                                    <li>Memory optimized  vps</li>
                                    <li>Storage optimized vps</li>
                                    <li>Multi-node cluster virtual machines</li>
                                </ul>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className="midn">
                            <AccordionSummary
                                expandIcon={<ExpandMore/>}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                className="mspkt"
                            >
                                Dedicated Bare metal  Servers 
                            </AccordionSummary>
                            <AccordionDetails className="mspkti">
                                 Get  dedicated bare metal servers to run your entire environment and reduce your IT spend down to very predictable costs and increase your profit margins. 
                                 We offer fully customized servers built to your specific needs with the right support to ensure you have everything you need to grow and scale your business 
                                <ul className="mspkti-ul">
                                    <li>Unmetered Traffic</li>
                                    <li>24/7 Server Monitoring</li>
                                    <li>DDOS Monitoring</li>
                                    <li>Custom Pricing</li>
                                    <li>Free server setup</li>
                                </ul>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </section>
                <div className="connecter">
                    <Connect />
                </div>
            </div>
        </>
    )
}

export default Hosting;