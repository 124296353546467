import React from 'react';
import './style.css';
import Cal from '../buttons/cal';
import {Link} from 'react-router-dom';



const Connect = () => {

    return(
        <>
            <div className="connectDiv">
               <h2 className="connect-head"><span className="liner">Get In Touch</span> And <br/>Let's Make It Happen</h2>
               <p className="connect-graph">
                    Ready to take your business to the next level? At DOHTECH SOLUTIONS, we’re passionate about driving your 
                    success with tailored, innovative solutions. Our team of experts is here to turn your vision into reality, 
                    schedule a free appointment today to get started.
               </p>
               <Cal text="Free Appointment" name="joinerbtn"/>
               <Link to="/contact" className="conn-btn" >Contact US Today</Link>
            </div>
        </>
    )
}

export default Connect;