import React, {useState} from 'react';
import './style.css';
import {Link} from 'react-router-dom';
import logo from '../../images/dohtech-head.png';
import {Menu, Close} from '@mui/icons-material';
import Cal from '../buttons/cal';


const Navbar = () => {

    const [isOpen,setIsOpen] = useState(false);

    const handleClick = () => {
        setIsOpen(!isOpen);
    }

    const closeMenu = () => setIsOpen(false);

    return(
        <>
           <nav className="main-nav">
            <div className="nav">
               <Link to="/"><img src={logo} alt="dohtech solutions logo" className="dts-logo" /></Link>
            </div>
            <div className="nav">
                <Link to="/dohtech-msp" className="nav-link">MSP</Link>
                <Link to="/dohtech-hosting" className="nav-link">Hosting</Link>
                {/* <Link to="/products" className="nav-link">Our Products</Link> */}
                <Link to="/services" className="nav-link">Services</Link>
                <Link to="/about" className="nav-link">About</Link>
                <Link to="/contact" className="nav-link">Contact</Link>
            </div>
            <div className="nav">
                <Cal text="Book Appointment" name="navebtn"/>
            </div>
           </nav>
           {/* mobile nav */}
           <nav className="mobile-Nav">
                <div className="nav-logo">
                    <Link to="/">
                        <img src={logo} alt="Dohtech Logo" className="dts-logo" />
                    </Link>
                </div>
                <div className="menu-nav" onClick={handleClick}>
                    { isOpen ? <Close className="menu-btn" onclick={handleClick}/> : <Menu onclick={handleClick} className="menu-btn"/>}
                </div>
                <ul className={isOpen ? "menu active" : "menu"} >
                    <li className="nav-link"><Link to="/dohtech-msp" className="links"  onClick={closeMenu}>Managed Service Provider</Link></li>
                    <div className="piner"></div>
                    <li className="nav-link"><Link to="/dohtech-hosting" className="links"  onClick={closeMenu}>App/Web Hosting</Link></li>
                    <div className="piner"></div>
                    <li className="nav-link"><Link to="/services" className="links"  onClick={closeMenu}>Our Services</Link></li>
                    <div className="piner"></div>
                    <li className="nav-link"><Link to="/about" className="links"  onClick={closeMenu}>About DOHTECH</Link></li>
                    <div className="piner"></div>
                    <li className="nav-link"><Link to="/contact" className="links"  onClick={closeMenu}>Contact DOHTECH</Link></li>
                    <div className="piner"></div>
                </ul>

           </nav>
        </>
    )
}

export default Navbar;