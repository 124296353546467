import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import './style.css';
import { toast } from 'react-toastify';
import {LocationOn, Phone, Email} from '@mui/icons-material';




const Contact = () => {
    const form = useRef();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [interest, setInterest] = useState('');
    const [company, setCompany] = useState('');


    const handleNameChange = (e) => {
        console.log(e.target.value);
        setName(e.target.value);
    }

    const handleEmailChange = (e) => {
        console.log(e.target.value);
        setEmail(e.target.value);
    }

    const handleMessageChange = (e) => {
        console.log(e.target.value);
        setMessage(e.target.value);
    }

    const handleInterestChange = (e) => {
        console.log(e.target.value);
        setInterest(e.target.value);
    }

    const handleCompanyChange = (e) => {
        console.log(e.target.value);
        setCompany(e.target.value);
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_9zlr18f', 'template_472ssk3', form.current, '6oCBFVkyfl7ltZPmf')
        .then((result) => {
            toast.success('Thanks for contacting us. We will reach out as soon as possible', {
                position: "top-right",
                autoClose: 6000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                })
            console.log(result.text);
        }, (error) => {
            toast.error('Error submitting your application. Please try again later', error)
            console.log(error.text);
        });

        setEmail('');
        setName('');
        setMessage('');
        setInterest('');
        setCompany('');

        e.target.reset();
    }

    return(
        <>
            <div className="contact-main">
                <div className="contact-hero">
                   <h3 className="contact-head">Get In Touch</h3>
                </div>
                <div className="contact-but">
                    <div className="contact-form">
                        <div className="form-up">
                            <h3 className="cont-head">Send US An Inquiry</h3>
                            <form ref={form} onSubmit={handleSubmit}  className="cont-form">
                                <div className="former">
                                    <div className="form-div">
                                        <label for="name" className="form-label">Your Name</label>
                                        <input 
                                            type="text"
                                            id="name"
                                            value={name}
                                            onChange={handleNameChange}
                                            className="form-input"
                                            required
                                            placeholder="Name"
                                        />
                                    </div>
                                    <div className="form-div">
                                        <label for="name" className="form-label">Your Email</label>
                                        <input 
                                            type="email"
                                            id="email"
                                            value={email}
                                            onChange={handleEmailChange}
                                            className="form-input"
                                            required
                                            placeholder="Email"
                                        />
                                    </div>
                                    <div className="form-div">
                                        <label for="website" className="form-label">Your Company Website</label>
                                        <input 
                                            type="url"
                                            id="company"
                                            value={company}
                                            onChange={handleCompanyChange}
                                            className="form-input"
                                            required
                                            placeholder="Company Website"
                                        />
                                    </div>
                                    <div className="form-div">
                                        <label for="type" className="form-label">Interested Service</label>
                                        <select id="type" 
                                            name="type" 
                                            required 
                                            onChange={handleInterestChange}
                                            value={interest}
                                            className="formit-select">
                                            <option value="" disabled >Select Interest</option>
                                            <option value="Custom Web Development">Custom Web Development</option>
                                            <option value="Mobile App Development">Mobile App Development</option>
                                            <option value="Enterprise Application">Enterprise Applications</option>
                                            <option value="IT Consulting & Support">IT Consulting & Suport</option>
                                            <option value="App/Website upgrades">App / Website Upgrades</option>
                                            <option value="Support & Maintenance">Support & Maintenance</option>
                                            <option value="dohtech hosting">DOHTECH Hosting</option>
                                            <option value="dohtech MSP">DOHTECH Managed Service Provider Services</option>
                                            <option value="IT trainig">IT Training</option>
                                        </select>
                                    </div>
                                    <div className="form-div">
                                        <label for="message" className="form-label">Message</label>
                                        <textarea 
                                            id="message" 
                                            required value={message} 
                                            name="message" rows="5" 
                                            cols="33" 
                                            onChange={handleMessageChange}
                                            className="formit-desc">
                                        </textarea>
                                    </div>
                                </div>
                                <button type="submit" className="formit-btn">Submit</button>
                            </form>
                        </div>
                        <div className="form-down">
                            <h3 className="info-head">Contact Information</h3>
                            <div className="info-div">
                                <div className="info">
                                    <div className="info-sect">
                                        <div className="inform ofice"><LocationOn className="info-ico"/></div>
                                        <div className="inform iform">
                                            <h2 className="info-hq">USA Office</h2>
                                            <h3 className="info-title">+1 9712641443</h3>
                                            <h3 className="info-title">main@dohtechsolutions.com</h3>
                                            <h3 className="info-title">dohtech1@gmail.com</h3>
                                            <h3 className="info-title"> 2512 East Evergreen Blvd - 1305 Vancouver, WA 98661 USA</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="info">
                                    <div className="info-sect">
                                        <div className="inform ofice"><LocationOn className="info-ico"/></div>
                                        <div className="inform iform">
                                            <h2 className="info-hq">Cameroon Office</h2>
                                            <h3 className="info-title">+237 670094945</h3>
                                            <h3 className="info-title">main@dohtechsolutions.com</h3>
                                            <h3 className="info-title"> Ndumbin Hall, Checkpoint Buea, Southwest Region Cameroon</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contact;