import React from 'react';
import './style.css';
import Cal from '../../components/buttons/cal';
import {Accordion, AccordionDetails, AccordionSummary, Typography,} from '@mui/material';
import {ExpandMore, SensorOccupied} from '@mui/icons-material';
import {Link} from 'react-router-dom';
import ilog from '../../images/softdev.jpg';
import ilog1 from '../../images/why.jpg';
import Connect from '../../components/connect/connect';



const Software = ()  => {


    
    return(
        <>
            <div className="soft-main">
                    <div className="softerr">
                        <h2 className="softhero-head">Business & Enterprise Software Development</h2>
                        <p className="softhero-graph">
                            Improve your business operations, performance and team productivity with purpose built applications designed to solve your specific business problems. 
                            Our software development services are designed to fit any use case, budget and project complexity ensuring a solution that addresses your current and future business problems.
                        </p>
                        <Cal text="Book Free Appointment" name="joinerbtn" />
                    </div>
                    <div className="dev-services">
                        <h1 className="dev-heading"> <span className="headline">Our</span> Software Development Services</h1>
                        <div className="devspace">
                            <div className="dev">
                               <Accordion className="midn">
                                    <AccordionSummary
                                        expandIcon={<ExpandMore/>}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        className="mspkt"
                                    >
                                        Custom Website Development
                                    </AccordionSummary>
                                    <AccordionDetails className="mspkti">
                                        Get a unique custom web application designed and built for your specific use case with industry leading features and functionalities. 
                                        We handle everything related to your project from research to support throughout the lifetime of your application.
                                        <ul className="mspkti-ul">
                                            <li>User research</li>
                                            <li>UI/UX Design</li>
                                            <li>Full Application Development</li>
                                            <li>Application hosting and deployment</li>
                                            <li>Application upgrade and maintenance</li>
                                            <li>Dependable 24/7 support</li>
                                        </ul>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion className="midn">
                                    <AccordionSummary
                                        expandIcon={<ExpandMore/>}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        className="mspkt"
                                    >
                                        Wordpress Website Development
                                    </AccordionSummary>
                                    <AccordionDetails className="mspkti">
                                        Get a unique custom Wordpress website for your small business with professional themes and plugins to enhance your business operations online.
                                        <ul className="mspkti-ul">
                                            <li>Custom Wordpress Theme Development</li>
                                            <li>Theme customization</li>
                                            <li>Custom Plugin Development</li>
                                            <li>Wordpress website fast hosting</li>
                                            <li>Wordpress website maintenance</li>
                                            <li>Dependable 24/7 support</li>
                                        </ul>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion className="midn">
                                    <AccordionSummary
                                        expandIcon={<ExpandMore/>}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        className="mspkt"
                                    >
                                        Mobile  Application Development
                                    </AccordionSummary>
                                    <AccordionDetails className="mspkti">
                                        Get an intuitive and innovative mobile application for your business with diverse functionalities to support your operations and improve productivity and customer experience. We build mobile apps using industry standard
                                        technologies to ensure your application can handle your unique use case and business.
                                        <ul className="mspkti-ul">
                                            <li>Android Mobile Application</li>
                                            <li>IOS Mobile Application</li>
                                            <li>Cross Platform Mobile Application</li>
                                            <li>Mobile Application Backend Maintenance & Hosting</li>
                                            <li>Mobile Application Maintenance</li>
                                            <li>Dependable 24/7 support</li>
                                        </ul>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion className="midn">
                                    <AccordionSummary
                                        expandIcon={<ExpandMore/>}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        className="mspkt"
                                    >
                                        Backend  Application Development
                                    </AccordionSummary>
                                    <AccordionDetails className="mspkti">
                                        Does your application need new backend features or capabilities or do you need your backend upgraded? Our team of engineers have the expertise to build and maintain any existing backend or 
                                        upgrade you application to address newer issues or business problems.
                                        <ul className="mspkti-ul">
                                            <li>Nodejs Backend Development</li>
                                            <li>Web Server Backend Development</li>
                                            <li>Python Backend Development</li>
                                            <li>Backend Application Hosting</li>
                                            <li>Backend Application Maintenance </li>
                                            <li>Dependable 24/7 support</li>
                                        </ul>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion className="midn">
                                    <AccordionSummary
                                        expandIcon={<ExpandMore/>}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        className="mspkt"
                                    >
                                        Startup Application Development
                                    </AccordionSummary>
                                    <AccordionDetails className="mspkti">
                                        Are you a Founder with an idea but lack the technical know how to build? DOHTECH SOLUTIONS can help bring your idea to life with full support every step of the way.
                                        We have a dedicated team focused on problem solving and innovation capable of building solutions to specific problems.
                                        <ul className="mspkti-ul">
                                            <li>Market Research Analysis</li>
                                            <li>UI/UX Design</li>
                                            <li>Technology Analysis </li>
                                            <li>Full Project Management</li>
                                            <li>Complete Development and Support</li>
                                            <li>Application deployment and Hosting</li>
                                            <li>Dependable 24/7 support</li>
                                        </ul>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion className="midn">
                                    <AccordionSummary
                                        expandIcon={<ExpandMore/>}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        className="mspkt"
                                    >
                                        Software Expansion
                                    </AccordionSummary>
                                    <AccordionDetails className="mspkti">
                                        Do you have an existing application with out of touch technologies and need help upgrading and expanding to handle new business challenges? DOHTECH SOLUTIONS possess the expertise to upgrade your application to handle
                                        current and future business challenges and provide support throughout the lifetime of your application.
                                        <ul className="mspkti-ul">
                                            <li>Feature Expansion</li>
                                            <li>Application Redesign</li>
                                            <li>Application Performance Enhancement</li>
                                            <li>Dependable 24/7 support</li>
                                        </ul>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion className="midn">
                                    <AccordionSummary
                                        expandIcon={<ExpandMore/>}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        className="mspkt"
                                    >
                                        Enterprise Software Consulting
                                    </AccordionSummary>
                                    <AccordionDetails className="mspkti">
                                        Unlock the full potential of your enterprise with DohTech Solutions, your premier partner for enterprise software consulting. 
                                        Our expert team delivers tailored, innovative solutions that drive efficiency, scalability, and competitive advantage.
                                        <ul className="mspkti-ul">
                                            <li>Technology Strategy and Roadmap Development</li>
                                            <li>Enterprise Architecture Consulting</li>
                                            <li>System Integration Services</li>
                                            <li>Cloud Consulting and Migration</li>
                                            <li>Business Process Automation</li>
                                            <li>Software selection and Implementation</li>
                                            <li>Change Management and Training</li>
                                            <li>Data Management and Analytics</li>
                                        </ul>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion className="midn">
                                    <AccordionSummary
                                        expandIcon={<ExpandMore/>}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        className="mspkt"
                                    >
                                        Enterprise Application Development
                                    </AccordionSummary>
                                    <AccordionDetails className="mspkti">
                                        DOHTECH SOLUTIONS offers a comprehensive suite of software application development services tailored to meet the unique needs of enterprises.

                                        <ul className="mspkti-ul">
                                            <li>Cloud Based Application Development</li>
                                            <li>Saas (Software As A Service ) Development</li>
                                            <li>API Development and Integration</li>
                                            <li>Legacy System Modernization</li>
                                            <li>IOT Application Development</li>
                                            <li>Ecommerce Solutions</li>
                                        </ul>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion className="midn">
                                    <AccordionSummary
                                        expandIcon={<ExpandMore/>}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                        className="mspkt"
                                    >
                                        Enterprise Application Support
                                    </AccordionSummary>
                                    <AccordionDetails className="mspkti">
                                        Ensure the seamless operation of your enterprise applications with DohTech Solutions. Our dedicated support services are designed to enhance 
                                        performance, reliability, and efficiency, keeping your business running smoothly.
                                        <ul className="mspkti-ul">
                                            <li>Backup and Disaster Recovery</li>
                                            <li>Patch Management and Updates</li>
                                            <li>Database Adminstration</li>
                                            <li>Reporting and Analytics</li>
                                            <li>Integration Support</li>
                                            <li>Service Level agreement (SLA) Management</li>
                                            <li>Incident and Problem Management</li>
                                            <li>User Support and Help Desk Management</li>
                                            <li>Security Management</li>
                                        </ul>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="dev">
                                <img src={ilog} alt="software dev" className="dev-img"/>
                            </div>
                        </div>
                    </div>
                    <div className="chooseDiv">
                        <h1 className="choose-head"><span className="headline">Why</span> Work With US</h1>
                        <p className="choose-graph">
                            Selecting the right partner for your business software needs is crucial for your business's success. Choosing DOHTECH SOLUTIONS means partnering with a 
                            team that is dedicated to your business’s success. Let us help you navigate the complexities of software development and support, empowering your business to achieve its full potential.
                        </p>
                        <div className="chooser">
                            <div className="choose">
                                <img src={ilog1} alt="software dev" className="dev-imgd"/>
                            </div>
                            <div className="choose">
                                <div className="choosen">
                                    <h3 className="cho-head">Proven Expertise and Experiences</h3>
                                    <p className="cho-graph">
                                        With our team of seasoned professionals, we bring extensive experience and deep technical knowledge across various industries. Our expertise ensures that we 
                                        understand your specific business challenges and can deliver robust, scalable solutions tailored to your needs.
                                    </p>
                                </div>
                                <div className="choosen">
                                    <h3 className="cho-head">Customized Solutions</h3>
                                    <p className="cho-graph">
                                    We specialize in creating bespoke software solutions that perfectly align with your business goals and operational processes. Our tailored approach 
                                    ensures that you receive solutions designed to meet your unique requirements, driving efficiency and growth.
                                    </p>
                                </div>
                                <div className="choosen">
                                    <h3 className="cho-head">Innovative Technologies</h3>
                                    <p className="cho-graph">
                                    DOHTECH SOLUTIONS leverages the latest technologies and industry best practices to deliver cutting-edge solutions. Whether it's AI, machine 
                                    learning, cloud computing, or IoT, we ensure your business stays ahead of the curve and remains competitive.
                                    </p>
                                </div>
                                <div className="choosen">
                                    <h3 className="cho-head">Proven ROI and Cost Efficiency</h3>
                                    <p className="cho-graph">
                                        Investing in our solutions translates to streamlined operations, reduced costs, and increased revenue. Our focus on delivering measurable business 
                                        outcomes ensures you get the best value for your investment, maximizing your return on investment.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="connectSect">
                        <Connect />
                    </div>
            </div>
        
        </>
    )
}

export default Software;