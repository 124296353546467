import React from 'react';
import './style.css';
import {Link} from 'react-router-dom';
import Cal from '../../components/buttons/cal';
import Connect from '../../components/connect/connect';
import {SupportAgent, Speed, PrecisionManufacturing, Savings, FollowTheSigns} from '@mui/icons-material';


const Home = () => {

    return(
        <>
           <div className="main-home">
                <section className="hero-main">
                    <h3 className="hero-head"><span className="colr">Innovating</span> and <span className="colr">Redefining</span> <br/> Online Business Solutions</h3>
                    <p className="hero-desc">
                        We help businesses manage, automate and scale their operations with cutting edge custom technologies 
                        built for each unique problem.
                    </p>
                    {/* <Link to="/services" className=""> <JoinBTN text="Free Appointment" />
                    </Link> */}
                    <Cal text="Free Appointment" name="joinerbtn"/>
                    <Link to="/services" className="hero-btn">Our Services</Link>
                </section>
                <section className="service-main">
                    <h2 className="serv-head"><span className="rline">Our</span> Key Services</h2>
                    <div className="serv-div">
                        <Link to="software-development" className="serv">
                            <div className="">
                                <img src="https://res.cloudinary.com/dohtech/image/upload/v1707545544/dohtech/softdev_hvz0hi.png" alt="software development" className="serv-img" />
                                <h3 className="serv-name">Software Development</h3>
                            </div>
                        </Link>
                        <Link to="/it-cloud-consulting" className="serv">
                            {/* <div className=""> */}
                                <img src="https://res.cloudinary.com/dohtech/image/upload/v1707545544/dohtech/server_klhax0.png" alt="software development" className="serv-img" />
                                <h3 className="serv-name">IT Support & Cloud Consulting</h3>
                            {/* </div> */}
                        </Link>
                        <Link to="/dohtech-training" className="serv">
                            <div className="">
                                <img src="https://res.cloudinary.com/dohtech/image/upload/v1707545544/dohtech/learning_k80edb.png" alt="software development" className="serv-img" />
                                <h3 className="serv-name">Software Development / IT Training</h3>
                            </div>
                        </Link>
                    </div>
                </section>
                <section className="soft-section">
                    <div className="soft">
                        <img src="https://res.cloudinary.com/dohtech/image/upload/v1707608267/dohtech/devo_sq65az.png" alt="app development" className="soft-img" />
                    </div>
                    <div className="soft">
                        <h3 className="soft-head"><span className="rline">Need</span> An App or Website Built?</h3>
                        <p className="soft-desc">
                            We provide a full suite of services that take your project from an idea to a complete application. From design and development to deployment and providing dependable 
                            application hosting and support, we ensure you have everything you need to scale your app to the right audience. <br/> <br/> 
                            Setup a free appointment today to discuss about your project's needs.
                        </p>
                        <div className="condiv">
                            <img src="https://res.cloudinary.com/dohtech/image/upload/v1711763108/dohtech/ap1_oksrzn.png" alt="app" className="con-img" />
                            <img src="https://res.cloudinary.com/dohtech/image/upload/v1711763105/dohtech/ap2_ikuz9m.png" alt="app" className="con-img" />
                            <img src="https://res.cloudinary.com/dohtech/image/upload/v1711763104/dohtech/ap4_jxjv4j.png" alt="app" className="con-img" />
                            <img src="https://res.cloudinary.com/dohtech/image/upload/v1711763105/dohtech/ap3_kwkisu.png" alt="app" className="con-img" />
                            <img src="https://res.cloudinary.com/dohtech/image/upload/v1711763104/dohtech/ap5_oaxxgm.png" alt="app" className="con-img" />
                        </div>
                        <Link to="software-development" className="softdev">Software Development</Link>
                    </div>
                </section>
                <section className="mid-section">
                    <h3 className="midhead"><span className="rlinee">Hosting</span> with Peace of Mind</h3>
                    <p className="midtxt">
                        Get the peace of mind you need to focus on running your business while we provide dependable hosting to keep your website and other apps running and maintained 24/7.
                        Our hosting services are designed to be flexible and provide convenience to  small and medium sized businesses with limited resources to maintain an inhouse team.
                    </p>
                    <Link to="/dohtech-hosting" className="mid-btn">DOHTECH Hosting</Link>
                </section>
                <section className="soft-section">
                    <div className="soft">
                        <h3 className="soft-head"><span className="rline">Dependable </span> IT Solutions</h3>
                        <p className="soft-desc e">
                            Run your business with confidence knowing your IT is in good hands with us as your Managed Service Provider managing every aspect of your IT. Our team of experienced engineers provide 24/7 
                            support to ensure that your business applications run smooth and securely. <br/><br/>
                            Get your business covered today and gain access to expertise, resources and technologies to enhance your business operations and increase productivity for your teams.
                        </p>
                        <img src="https://res.cloudinary.com/dohtech/image/upload/v1711614443/dohtech/consult_drtnjd.png" alt="tech consulting" className="down-img" />
                    </div>
                    <div className="soft t">
                        <div className="soft-div">
                            <div className="sft">
                                <SupportAgent className="sft-ico" />
                                <h3 className="sft-head">24/7 IT Support & Monitoring</h3>
                                <p className="sft-desc">
                                    Faster issue detection and resolution
                                </p>
                            </div>
                            <div className="sft">
                                <Speed className="sft-ico" />
                                <h3 className="sft-head">Quicker Deployments</h3>
                                <p className="sft-desc">
                                    Faster project planning and execution
                                </p>
                            </div>
                            <div className="sft">
                                <PrecisionManufacturing className="sft-ico" />
                                <h3 className="sft-head">Automation & Efficiency</h3>
                                <p className="sft-desc">
                                    Custom technologies to reduce manual processes
                                </p>
                            </div>
                            <div className="sft">
                                <Savings className="sft-ico" />
                                <h3 className="sft-head">Reduced Total IT Expenditure</h3>
                                <p className="sft-desc">
                                    Stay on budget with tech that runs your business
                                </p>
                            </div>
                            <div className="sft">
                                <FollowTheSigns className="sft-ico" />
                                <h3 className="sft-head">Free consulting for New tech</h3>
                                <p className="sft-desc">
                                    Get expert recommendations on best techs to solve your business problems
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="soft-section">
                    <div className="soft">
                        <h3 className="soft-head"><span className="rline">Outsource</span> For Better Efficiency</h3>
                        <p className="soft-desc e">
                        Unlock unparalleled efficiency and scalability for your business with our comprehensive IT and cloud server management services. Our expert team ensures 
                        seamless operation, optimal performance, and robust security for your infrastructure, allowing you to focus on driving innovation and growth.
                        </p>
                        <div className="condiv">
                            <img src="https://res.cloudinary.com/dohtech/image/upload/v1711769077/dohtech/it2_jlhhe0.png" alt="app" className="conimg" />
                            <img src="https://res.cloudinary.com/dohtech/image/upload/v1711769076/dohtech/it3_onj36f.png" alt="app" className="conimg" />
                            <img src="https://res.cloudinary.com/dohtech/image/upload/v1711769080/dohtech/it1_grcohg.png" alt="app" className="conimg" />
                            <img src="https://res.cloudinary.com/dohtech/image/upload/v1711769076/dohtech/it4_wpjss4.png" alt="app" className="conimg" />
                        </div>
                        <Link to="/dohtech-msp" className="soft-btn">DOHTECH MSP</Link>
                    </div>
                    <div className="soft">
                        <img src="https://res.cloudinary.com/dohtech/image/upload/v1693194764/dohtech/dts-services_ofrbmw.png" alt="IT cloud consulting" className="itimg" />
                    </div>
                    <Connect />
                </section>
           </div>
        </>
    )
}

export default Home;