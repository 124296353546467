import './App.css';
import {Routes, Route} from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from 'react-toastify';
import Navbar from './components/navbar/navbar';
import Footer from './components/footer/footer';
import Home from './pages/home/home';
import Services from './pages/services/services';
import Contact from './pages/Contact/contact';
import About from './pages/about/about';
import MSP from './pages/msp/msp';
import Hosting from './pages/hosting/hosting';
import Software from './pages/software/software';
import Consulting from './pages/software/support';
import Training from './pages/software/training';


function App() {
  return (
    <>
    <Navbar />
    <Routes>
      <Route path="/" exact element={<Home />} />
      <Route path="/services"  element={<Services />} />
      <Route path="/dohtech-msp"  element={<MSP />} />
      <Route path="/dohtech-hosting"  element={<Hosting />} />
      <Route path="/contact"  element={<Contact />} />
      <Route path="/about" element={<About />} />
      <Route path="/software-development" element={<Software />} />
      <Route path="/it-cloud-consulting" element={<Consulting />} />
      <Route path="/dohtech-training" element={<Training />} />
    </Routes>
    <Footer />
    </>
  );
}

export default App;
